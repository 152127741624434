/* eslint-disable react-hooks/exhaustive-deps */
import {
  EventActions,
  HubActions,
  OtherActions,
  UserActions,
} from "../../store/actions";

import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks";

import { useTranslation } from "react-i18next";

import { scrollToElem } from "@/utils/interfaceHelpers.js";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { ReactComponent as ShareIcon } from "../../assets/images/icon_share.svg";

import Jumbotron from "react-bootstrap/Jumbotron";

import EventFilterGroupContainer from "../../containers/EventFilterGroupContainer";

import { HeroBanner } from "../../components/HeroBanner";

import { ErrorPage } from "../ErrorPage";

import "./ProductLandingPage.scss";

import { headlessEngine } from "@/configureCoveo";
import { loadPaginationActions } from "@coveo/headless";

import { Facet, FacetDateRange, FacetToggle } from "@/components/Coveo/Facet";
import { Pager } from "@/components/Coveo/Pager";
import { QuerySummary } from "@/components/Coveo/QuerySummary";
import { ResultList } from "@/components/Coveo/ResultList";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
// import { ECCFilterTabs } from "@/components/Coveo/ECCFilterTabs";
import { ProductFilterTabs } from "@/components/Coveo/ProductFilterTabs";
import { SearchPills } from "@/components/Coveo/SearchPills";
import { bindUrlManager } from "@/components/Coveo/UrlManager";
import {
  breadcrumbs,
  categoryFacet,
  // portraitsTab,
  coachingTab,
  customerRoleFacet,
  dateFacet,
  deliveryRoleExpertiseFacet,
  deliveryTypeSubTypeFacet,
  // allTab,
  eventsTab,
  eventTypeFacet,
  headlessResultTemplateManager,
  languageFacet,
  pager,
  productFacet,
  querySummary,
  regionFacet,
  resultList,
  searchParametersBreadcrumbs,
  searchParametersFacet,
  searchParametersFacetDateRange,
  subCategoryFacet,
  tierFacet,
} from "@/controllers/CoveoControllers";

const {
  loadEventFilterGroup,
  loadProductTopicGroup,
  updateEventFiltersSilent,
} = EventActions;

const { clearHubEventFilters } = HubActions;

const { getUserFavoritesAll } = UserActions;

const { toggleShareModal } = OtherActions;

const ProductLandingPage = ({
  user,
  listInit,
  listCurrent,
  timezone,
  hubId,
  hubConfig,
  topicList,
  favorites,
  tb_id,
  hubFeaturedEvent,
  notFoundId,
  history,
  language,
  isLoading,
  location,
  appConfig,
  criticalError,
  dispatch,
  locale,
  userSearchToken,
}) => {
  const { t } = useTranslation();

  var coachingProductFilter = [hubId];
  // coaching
  switch (hubId) {
    case "analytics":
      coachingProductFilter = ["crm-analytics", "tableau"];
      break;
    case "datacloud":
      coachingProductFilter = ["data-cloud"];
      break;
    case "experiences":
      coachingProductFilter = ["experience"];
      break;
    case "industries":
      coachingProductFilter = [
        "communications",
        "consumer-goods",
        "financial-services",
        "healthcare-&-life-sciences",
        "industries",
        "manufacturing",
        "strategy-&-planning",
      ];
      break;
    case "marketing":
      coachingProductFilter = [
        "marketing",
        "marketing-cloud-account-engagement",
        "marketing-cloud-intelligence",
        "marketing-cloud-personalization",
      ];
      break;
    case "mulesoft":
      coachingProductFilter = ["integration"];
      break;
    case "slack":
      coachingProductFilter = [];
      break;
    default:
      break;
  }

  var eventsProductFilter = [hubId];
  // coaching
  switch (hubId) {
    case "analytics":
      eventsProductFilter = ["tableau-crm", "tableau"];
      break;
    case "commerce":
      eventsProductFilter = ["b2b-commerce", "b2c-commerce"];
      break;
    case "datacloud":
      eventsProductFilter = ["data-cloud"];
      break;
    case "experiences":
      eventsProductFilter = ["experience-cloud"];
      break;
    case "industries":
      eventsProductFilter = [
        "communications-cloud",
        "consumer-goods-cloud",
        "education-cloud",
        "financial-services-cloud",
        "health-cloud",
        "manufacturing-cloud",
        "media-cloud",
        "nonprofit-cloud",
        "revenue-cloud",
      ];
      break;
    case "marketing":
      eventsProductFilter = ["marketing-cloud", "marketing-cloud-intelligence"];
      break;
    case "mulesoft":
      eventsProductFilter = ["integration"];
      break;
    case "platform":
      eventsProductFilter = ["salesforce-platform"];
      break;
    case "sales":
      eventsProductFilter = ["sales-cloud"];
      break;
    case "service":
      eventsProductFilter = ["service-cloud"];
      break;
    case "success":
      eventsProductFilter = ["success-plans"];
      break;
    // case "slack":
    //   eventsProductFilter = [];
    //   break;
    default:
      break;
  }

  const expertCoachingProducts = () => {
    let products = coachingProductFilter.join(",").replaceAll("&", "%26");
    let filterPrefix = "&f[successeventproduct]=" + products;
    return filterPrefix;
  };
  const expertCoachingFilter = expertCoachingProducts();

  const [windowWidth] = useWindowSize();
  const mobileBreak = 991;

  const [isExpanded, setIsExpanded] = useState(false);

  let coveoListTitle = hubId ? t("All Events " + hubId) : ""; //"All " + hubId + " Events";
  let coveoListDescription;
  const decoratedOnClick = () => {
    if (windowWidth <= mobileBreak) {
      setIsExpanded(!isExpanded);
    }
  };
  const [searchParametersState, setSearchParametersState] = useState(
    searchParametersFacet.state
  );

  useEffect(() => {
    headlessEngine.executeFirstSearch();
  }, []);

  useEffect(() => {
    loadProductFilterGroups();
    loadProductTopicGroups();

    if (tb_id && !hasFavoritesInit) {
      dispatch(getUserFavoritesAll({ tb_id }));
      setHasFavoritesInit(true);
    }

    // clear search terms on homepage
    const filterObjectSearch = { key: "search", values: null };
    dispatch(updateEventFiltersSilent(filterObjectSearch));
  }, [hubId, location]);

  useEffect(() => {
    if (
      hubConfig &&
      hubConfig.filterConfig &&
      hubConfig.filterConfig.userFiltersActive !== undefined
    ) {
      setUserFiltersActive(hubConfig.filterConfig.userFiltersActive);
      setUserFiltersActiveNum(hubConfig.filterConfig.userFiltersActiveNum);
      setHasUserFiltersInit(true);
    }

    //Coveo
    const unsubscribeUrlManager = bindUrlManager(headlessEngine);
    const searchParametersSubcribe = searchParametersFacet.subscribe(() => {
      let params = searchParametersFacet.state;
      setSearchParametersState(params);
    });

    updateCoveoFilters();

    return () => {
      unsubscribeUrlManager();
      searchParametersSubcribe();
    };
  }, [hubConfig, userSearchToken, searchParametersFacet, productFacet]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (productFacet.state.canShowMoreValues) {
        productFacet.showMoreValues();
      }
      if (languageFacet.state.canShowMoreValues) {
        languageFacet.showMoreValues();
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    updateCoveoFilters();
    return () => {
      productFacet.deselectAll();
      languageFacet.deselectAll();
    };
  }, [coachingTab.state.isActive, eventsTab.state.isActive]);

  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);
  const [userFiltersActive, setUserFiltersActive] = useState(false);
  const [userFiltersActiveNum, setUserFiltersActiveNum] = useState(false);

  const [hasUserFiltersInit, setHasUserFiltersInit] = useState(false);

  const updateCoveoFilters = () => {
    productFacet.deselectAll();
    languageFacet.deselectAll();

    // check for a user and set language
    if (language || (user && user.tb_id)) {
      let preferredLanguage = language || user.preferred_language;
      // get all the facet values
      let languageFacetValueArr = languageFacet.state.values;
      // search for matching language
      let languageFacetSelect = languageFacetValueArr.map((value) => {
        // return true if so
        if (value.value === preferredLanguage) {
          return true;
        }
        // default false
        return false;
      });

      if (languageFacetSelect) {
        // select the value & set state
        languageFacet.toggleSelect({
          value: preferredLanguage,
          state: "selected",
        });
      }
    }

    let selectedProductFilters;
    if (coachingTab.state.isActive) {
      selectedProductFilters = coachingProductFilter;
    } else {
      selectedProductFilters = eventsProductFilter;
    }

    if (selectedProductFilters) {
      let productFilterValueArr = productFacet.state.values;
      for (const filter of selectedProductFilters) {
        let productSelect = productFilterValueArr.map((value) => {
          if (value.value === filter) {
            return true;
          }
          return false;
        });
        if (productSelect) {
          productFacet.toggleSelect({
            value: filter,
            state: "selected",
          });
        }
      }
    }
  };

  const loadProductFilterGroups = () => {
    productFilterGroups.map((group, index) =>
      dispatch(loadEventFilterGroup(group))
    );
  };

  const loadProductTopicGroups = () => {
    const group = {
      businessLine: hubId,
    };
    dispatch(loadProductTopicGroup(group));
  };

  const seeAllEvents = () => {
    scrollToElem("hubGroupHeader");
  };

  const resetPage = () => {
    const { updatePage } = loadPaginationActions(headlessEngine);
    headlessEngine.dispatch(updatePage(1));
  };

  const showShareModal = () => {
    let modalPayload = { show: true };
    dispatch(toggleShareModal(modalPayload));
  };

  const clearAllHandler = (key, value) => {
    let filterObject = { hubId: hubId };
    dispatch(clearHubEventFilters(filterObject));
    productFacet.deselectAll();
    languageFacet.deselectAll();
    resetPage();
  };

  const productLines = appConfig.productLines[hubId];

  const productFilterGroups = [
    {
      groupkey: "group-upcoming-events",
      filters: {
        product: productLines,
      },
      title: "PLP Featured Upcoming Events",
    },
    {
      groupkey: "group-on-demand-events",
      filters: {
        product: productLines,
        type: ["video"],
      },
      title: "PLP Recent On-Demand Events",
    },
  ];

  const hubDetail = {
    slug: hubId,
    filter_config: {
      mode: "product",
      product: productLines,
    },
  };

  let productTopics = {};
  if (topicList) {
    topicList.forEach((portrait) => {
      if (!productTopics.hasOwnProperty(portrait.subPhase)) {
        productTopics[portrait.subPhase] = [];
      }
      productTopics[portrait.subPhase].push({
        title: portrait.title,
        link: `/portraits/${portrait.portraitID}`,
      });
    });
  }

  const pageTitle =
    t(`Product Line Title ${hubId}`) + " - " + t("Customer Success Events");

  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  if (productLines === undefined) {
    return <ErrorPage errorType="404" />;
  }

  const RenderProductFilterGroup = ({ start, end, mode = "product" }) => {
    return (
      <Container>
        <Row>
          <Col xs={12} lg={12}>
            {productFilterGroups.slice(start, end).map((group, index) => (
              <EventFilterGroupContainer
                groupKey={group.groupkey}
                groupConfig={group}
                key={group.groupkey}
                mode={mode}
              />
            ))}
          </Col>
        </Row>
      </Container>
    );
  };

  const RenderExpertCoachingTopicsSection = () => {
    if (Object.keys(productTopics).length === 0) {
      return <></>;
    } else {
      const sectionHeights = {};
      Object.entries(productTopics).forEach((topic) => {
        const [key, value] = topic;

        if (value.length > 9) {
          sectionHeights[key] = "tall";
        } else if (value.length > 4) {
          sectionHeights[key] = "medium";
        } else {
          sectionHeights[key] = "small";
        }
      });
      return (
        <Container>
          <Row>
            <Col xs={12}>
              <div className="expert-coaching-section">
                <div className="expert-coaching-section--header">
                  <h3 className="groupHeader">
                    {t("PLP Expert Coaching Topics")}
                  </h3>
                </div>
                {productTopics &&
                  Object.keys(productTopics).map((key) => (
                    <>
                      <h4>{t(`Portrait Phase ${key}`)}</h4>
                      <div className={`topics ${sectionHeights[key]}`}>
                        {productTopics[key].map((portrait) => (
                          <Link key={portrait.portraitID} to={portrait.link}>
                            {portrait.title}
                          </Link>
                        ))}
                      </div>
                    </>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  const RenderDiscoverExpertCoachingTopicsSection = () => {
    return (
      <div className="discoverExpertCoaching">
        <h2 className="groupHeader">{t("Discover Expert Coaching Topics")}</h2>
        <p className="eccDescription">
          {t("Expert Coaching Description expertcoaching standard")}
        </p>
        <Link
          to={{
            pathname: "/search",
            hash: "#tab=Coaching" + expertCoachingFilter,
          }}
          className="btn btn-primary"
        >
          {t("Explore All Events")}
        </Link>
      </div>
    );
  };

  const RenderCoveoEventsList = () => {
    return (
      <div
        className="coveo-events-list insetPage"
        id="coveo-events-list-container"
      >
        <Container className="fullPanel">
          <Row>
            <h2 className="groupHeader">{t(coveoListTitle)}</h2>
            <p class="eccDescription">{t(coveoListDescription)}</p>
          </Row>
          <Row>
            <Col xs={12} lg={4}>
              <div className="colPanel filterBar coveo--filterBar">
                <Accordion
                  activeKey={
                    isExpanded || windowWidth > mobileBreak ? "1" : "0"
                  }
                  className={
                    isExpanded || windowWidth > mobileBreak
                      ? "expanded"
                      : "collapsed"
                  }
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    onClick={decoratedOnClick}
                    className="filterBarToggleBtn"
                  >
                    <h4>{t("Filter Results")}</h4>
                  </Accordion.Toggle>

                  <Button
                    variant="secondary"
                    className="shareButton "
                    onClick={showShareModal}
                  >
                    {t("Share this View")} <ShareIcon />
                  </Button>
                  <Button
                    onClick={showShareModal}
                    variant="link"
                    className="shareButtonInline"
                  >
                    {t("Share this View")}
                  </Button>

                  <Accordion.Collapse eventKey="1">
                    <ListGroup>
                      <Facet
                        controller={productFacet}
                        title={appConfig.filters.product.title}
                        configOptions={appConfig.filters.product.options}
                        searchParams={searchParametersState}
                        facetId="productFacet"
                        key="productFacetKey"
                      />
                      {/* <Facet
                        controller={businesslineFacet}
                        title={appConfig.filters.businessline.title}
                        configOptions={appConfig.filters.businessline.options}
                        searchParams={searchParametersState}
                        facetId="businesslineFacet"
                        key="businesslineFacetKey"
                      /> */}
                      <Facet
                        controller={categoryFacet}
                        title={appConfig.filters.category.title}
                        configOptions={appConfig.filters.category.options}
                        searchParams={searchParametersState}
                        facetId="categoryFacet"
                        key="categoryFacetKey"
                      />
                      <Facet
                        controller={subCategoryFacet}
                        title={appConfig.filters.subCategory.title}
                        configOptions={appConfig.filters.subCategory.options}
                        searchParams={searchParametersState}
                        facetId="subCategoryFacet"
                        key="subCategoryFacetKey"
                      />
                      <Facet
                        controller={customerRoleFacet}
                        title={appConfig.filters.targetCustomerRole.title}
                        configOptions={
                          appConfig.filters.targetCustomerRole.options
                        }
                        searchParams={searchParametersState}
                        facetId="targetCustomerRoleFacet"
                        key="targetCustomerRoleFacetKey"
                      />
                      <Facet
                        controller={eventTypeFacet}
                        title={appConfig.filters.eventType.title}
                        configOptions={appConfig.filters.eventType.options}
                        searchParams={searchParametersState}
                        facetId="eventFacet"
                        key="eventFacetKey"
                      />{" "}
                      <Facet
                        controller={deliveryTypeSubTypeFacet}
                        title={appConfig.filters.deliveryTypeSubType.title}
                        configOptions={
                          appConfig.filters.deliveryTypeSubType.options
                        }
                        searchParams={searchParametersState}
                        facetId="deliveryTypeSubTypeFacet"
                        key="eventFacetKey"
                      />{" "}
                      {/* <Facet
                        controller={businesslineFacet}
                        title={t("Facet Title Business Line")}
                        configOptions={[]}
                        stringPrefix="Product Line Title "
                        searchParams={searchParametersState}
                        facetId="businesslineFacet"
                        key="businesslineFacet"
                      /> */}
                      {/* <Facet
                        controller={customerlifecycleFacet}
                        title={t("Facet Title Customer Lifecycle")}
                        configOptions={[]}
                        stringPrefix="Portrait Phase "
                        searchParams={searchParametersState}
                        facetId="customerlifecycleFacet"
                        key="customerlifecycleFacet"
                      /> */}
                      <Facet
                        controller={languageFacet}
                        title={appConfig.filters.language.title}
                        configOptions={appConfig.filters.language.options}
                        searchParams={searchParametersState}
                        facetId="languageFacet"
                        key="languageFacetKey"
                      />
                      <Facet
                        controller={regionFacet}
                        title={appConfig.filters.region.title}
                        configOptions={appConfig.filters.region.options}
                        searchParams={searchParametersState}
                        facetId="regionFacet"
                        key="regionFacetKey"
                      />
                      <Facet
                        controller={deliveryRoleExpertiseFacet}
                        title={appConfig.filters.deliveryRoleExpertise.title}
                        configOptions={
                          appConfig.filters.deliveryRoleExpertise.options
                        }
                        searchParams={searchParametersState}
                        facetId="deliveryRoleExpertiseFacet"
                        key="deliveryRoleExpertiseFacetKey"
                      />
                      <FacetDateRange
                        controller={dateFacet}
                        searchParametersController={
                          searchParametersFacetDateRange
                        }
                        title="Date Range"
                        timezone={timezone}
                        locale={location}
                        key="dateFacetKey"
                        isMobile={windowWidth <= mobileBreak}
                        searchParams={searchParametersState}
                      />
                      <FacetToggle
                        controller={tierFacet}
                        title="Tier"
                        key="tierFacetKey"
                      />
                    </ListGroup>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <div className="colPanel">
                {/* <FilterTabs
                  allController={eventsTab}
                  onTabChange={resetPage}
                /> */}
                <ProductFilterTabs
                  // allController={allTab}
                  eventsController={eventsTab}
                  // portraitsController={portraitsTab}
                  coachingController={coachingTab}
                  onTabChange={resetPage}
                />
                <div className="coveo--result-header">
                  <QuerySummary controller={querySummary} />
                  <SearchPills
                    controller={breadcrumbs}
                    searchParametersController={searchParametersBreadcrumbs}
                    timezone={timezone}
                    locale={locale}
                    configFilters={appConfig.filters}
                  />
                </div>
                <ResultList
                  controller={resultList}
                  templateManager={headlessResultTemplateManager}
                  cardStyle="overview"
                />
                <Pager
                  controller={pager}
                  element="coveo-events-list-container"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const metaDescription = t("Default Meta Description");

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
      </Helmet>
      <HeroBanner
        minHeight="379px"
        eventProductKey="plp"
        eventTypeKey={`plp-${hubId}`}
        user={user}
      >
        <Row>
          <Col xs={10} md={6} lg={6}>
            <Jumbotron>
              <h1>{t(`Product Line Title ${hubId}`)}</h1>
              <p>{t(`Product Line Description ${hubId}`)}</p>

              {!userFiltersActive ? (
                <Link
                  to={{
                    pathname: "/search",
                    hash: "#tab=Coaching" + expertCoachingFilter,
                  }}
                  className="btn btn-primary"
                >
                  {t("See All Events")}
                </Link>
              ) : (
                // <Button variant="primary" onClick={seeAllEvents}>
                //   {t("See All Events")}
                // </Button>
                <>
                  <Button variant="secondary" onClick={seeAllEvents}>
                    {`(${userFiltersActiveNum}) ${
                      userFiltersActiveNum > 1
                        ? t("Filters Applied")
                        : t("Filter Applied")
                    }`}
                  </Button>

                  <button
                    type="button"
                    className="heroSubLink btn btn-link"
                    onClick={() => {
                      clearAllHandler();
                    }}
                  >
                    {t("Clear All Filters")}
                  </button>
                </>
              )}
            </Jumbotron>
          </Col>
        </Row>
      </HeroBanner>
      <div id="filterContentStart"></div>

      {/* {hasUserFiltersInit && ( */}
      <>
        <CSSTransition
          appear
          in={!userFiltersActive}
          timeout={100}
          classNames="panel"
          unmountOnExit
        >
          <RenderProductFilterGroup start={0} end={1} mode="featured" />
        </CSSTransition>
        {/*<CSSTransition
            appear
            in={!userFiltersActive}
            timeout={100}
            classNames="panel"
            unmountOnExit
          >
            <RenderExpertCoachingTopicsSection />
          </CSSTransition>*/}
        <CSSTransition
          appear
          in={!userFiltersActive}
          timeout={100}
          classNames="panel"
          unmountOnExit
        >
          <RenderProductFilterGroup start={1} end={2} mode="bottomborder" />
        </CSSTransition>
        <CSSTransition
          appear
          in={!userFiltersActive}
          timeout={100}
          classNames="panel"
          unmountOnExit
        >
          <RenderDiscoverExpertCoachingTopicsSection />
        </CSSTransition>
      </>
      {/* )} */}

      {/* <HubFilterListContainer
        key={`hublist-${hubId}`}
        businessLineId={hubId}
        hubDetail={hubDetail}
        numPerPage={10}
      /> */}
      <RenderCoveoEventsList />
    </div>
  );
};

export default ProductLandingPage;
