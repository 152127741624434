/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import "./ProductFilterTabs.scss";

export const ProductFilterTabs = (props) => {
  const {
    coachingController,
    eventsController,
    onTabChange,
  } = props;
  const [eventsState, setEventsState] = useState(eventsController.state);
  const [coachingState, setCoachingState] = useState(coachingController.state);

  const { t } = useTranslation();

  useEffect(() => {
    const eventsSubscribe = eventsController.subscribe(() =>
        setEventsState(eventsController.state)
      );

      const coachingSubscribe = coachingController.subscribe(() =>
        setCoachingState(coachingController.state)
      );

    return () => {
      eventsSubscribe();
      coachingSubscribe();
    };
  }, [
    eventsController,
    coachingController,
  ]);

  const setTab = (key) => {
    onTabChange();
    switch (key) {
        case "events":
            eventsController.select();
            break;
        case "coaching":
            coachingController.select();
            break;
      default:
        coachingController.select();
        break;
    }
  };

  const scrollToTab = (tabId) => {
    const tabsElement = document.querySelector(".coveo--filter-tabs");
    const tabElement = document
      .getElementById(`filter-tabs-tab-${tabId}`)
      .getBoundingClientRect();
    tabsElement.scrollTo({
      top: tabElement.top,
      left: tabElement.left,
      behavior: "smooth",
    });
  };

  let tabKey = "events";

  if (coachingController.state.isActive) {
    tabKey = "coaching";
  }
  if (eventsController.state.isActive) {
    tabKey = "events";
  }

  return (
    <Tabs
      id="filter-tabs"
      className="coveo--filter-tabs"
      activeKey={tabKey}
      onSelect={(k) => setTab(k)}
    >
        <Tab eventKey="events" title={t("Search Events Tab")} />
        <Tab eventKey="coaching" title={t("Search Coaching Tab")} />
    </Tabs>
  );
};