import React from "react";
// import PropTypes from "prop-types";
import {
  useDurationFormat,
  useEventTranslation,
  useEventTranslationBulk,
  useTextTruncate,
} from "../../hooks/";
import { CLIENT_DOMAIN } from "../../config/environment";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import Moment from "react-moment";
import AddToCalendar from "react-add-to-calendar";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";

import VideoController from "@/controllers/VideoController";

import { ReactComponent as ShareIcon } from "../../assets/images/icon_share.svg";
import { ReactComponent as VideoPlayIcon } from "../../assets/images/icon_video_play_outline.svg";

import { OtherActions } from "../../store/actions";
import { EventLink } from "../EventLink";
import Like from "../Like/Like";
import { formatTextContent } from "@/utils/stringHelpers";

import "./Event.scss";
const { toggleShareModal } = OtherActions;

const Event = ({
  id,
  tb_id,
  tb_user_plan,
  user,
  title,
  event,
  category,
  product,
  region,
  eventType,
  address,
  eventLanguage,
  startDateTime,
  endDateTime,
  sessionLength,
  externalDetailUrl,
  overview,
  showBtns,
  minimized,
  showLikes = true,
  showPremierTag = true,
  primaryFeature,
  portraitFeature,
  portraitFeatureBackground,
  cardStyle,
  showDescription,
  eventGroupType,
  eventTier,
  isFavorite,
  launchLink,
  recordingID,
  recordingType,
  topic,
  levelOfExpertise,
  role,
}) => {
  const dispatch = useDispatch();
  const isVideo =
    eventType &&
    eventType.some((typeItem, typeIndex) => {
      return typeItem === "video";
    });

  let cardClass = "eventCard";
  cardClass = isVideo ? cardClass + " videoCard" : cardClass;
  cardClass = minimized ? cardClass + " eventMin" : cardClass;
  cardClass = portraitFeature ? cardClass + " portraitFeature" : cardClass;
  cardClass = showBtns ? cardClass + " withBtns" : cardClass;
  cardClass = cardStyle === "ecctrendingtopics" ? cardClass + " trendingTopicCard" : cardClass;
  cardClass = cardStyle === "eccondemandsessions" ? cardClass + " onDemandCard" : cardClass;

  const eventTypeKey = eventType ? eventType[0] : null;
  const eventTypeTranslated = useEventTranslation("eventType", eventTypeKey);

  const eventProductKey = product ? product[0] : null;
  const eventProductTranslated = useEventTranslation(
    "product",
    eventProductKey
  );

  const productTagsTranslated = useEventTranslationBulk("product", product);
  const categoryTagsTranslated = useEventTranslationBulk("category", category);
  /*const languageTagTranslated = useEventTranslation(
    "eventLanguage",
    eventLanguage
  );*/

  const { t } = useTranslation();

  const start = moment(startDateTime);
  const durationStr = useDurationFormat(sessionLength, "short");

  const titleTruncated = useTextTruncate(title, {
    length: cardStyle === "ecctrendingtopics" || cardStyle === "eccondemandsessions" ? 100 : 75,
    ending: "...",
  });

  const metadataConcat = [topic, levelOfExpertise, role, eventLanguage].filter(str => str).join(', ');
  const overviewTruncated = useTextTruncate(
    overview,
    {
      length: cardStyle === "ecctrendingtopics" || cardStyle === "eccondemandsessions" ? 175 : 105,
      ending: "...",
    },
    true
  );

  let eventLinkProps = {};
  if (externalDetailUrl && eventTypeKey != 'virtual-interactive-workshop') {
    eventLinkProps.href = externalDetailUrl;
    eventLinkProps.target = "_blank";
  } else {
    eventLinkProps.to = { pathname: "/events/" + id };
  }

  const showShareModal = () => {
    let modalPayload = {
      show: true,
      shareUrl: `${window.location.origin}/events/${id}`,
    };
    dispatch(toggleShareModal(modalPayload));
  };

  const eventCardStyle = portraitFeatureBackground
    ? {
        backgroundImage: `url(${portraitFeatureBackground})`,
      }
    : {};

  const sameDayFlag = moment(startDateTime).isSame(moment(endDateTime), "day");
  const isBucketParent = eventGroupType === "Bucket Parent";

  const renderCallout = () => {
    const startDateFormat = isBucketParent
      ? sameDayFlag
        ? "MMM D, YYYY"
        : "MMM D"
      : "h:mm A z";
    return !isVideo ? (
      start.isSame(moment().clone().startOf("day"), "d") ? (
        <div className="labelCallout">
          <span className="labelCallout--text">{t("Today")}</span>
          <span className="labelCallout--time">
            <Moment date={startDateTime} format="h:mm A z" />
          </span>
        </div>
      ) : (
        <div className="dateCallout">
          <span className="dateCallout--month">
            <Moment date={startDateTime} format="MMM" />
          </span>
          <span className="dateCallout--day">
            <Moment date={startDateTime} format="D" />
            {isBucketParent && !sameDayFlag && (
              <span className="dateSup">+</span>
            )}
          </span>
          <span className="labelCallout--time">
            <Moment date={startDateTime} format={startDateFormat} />
            {!sameDayFlag && isBucketParent && (
              <>
                {" - "}
                <Moment date={endDateTime} format={startDateFormat} />
              </>
            )}
          </span>
        </div>
      )
    ) : (
      <div className="vidCallout">
        <span className="vidCallout--icon">
          <VideoPlayIcon />
        </span>
        <span className="vidCallout--time">{durationStr}</span>
      </div>
    );
  };

  const renderHeaderSub = () => {
    return !minimized ? (
      <div className="eventHeaderSub">
        {renderEventTags()}
        {/* {renderECCMetadata()} */}
      </div>
    ) : (
      <div className="eventHeaderSub">
        <span className="eventHeaderSub--item accentItem">
          {titleTruncated}
        </span>
      </div>
    );
  };

  const renderPortraitFeature = () => {
    return portraitFeature ? (
      <>
        {isVideo && (
          <div>
            <VideoController
              embed_id={recordingID}
              embed_type={recordingType}
              /*onVideoLoad={videoOnLoad}*/
              user={user}
            />
          </div>
        )}
        <div className="portraitFeatureDetails">
          <Like
            id={id}
            tb_id={tb_id}
            t={t}
            isFavorite={isFavorite}
            event={event}
          />
          <span className="eventSub eyebrow">
            {eventTypeTranslated && eventTypeTranslated}
          </span>
          <span className="eventTitle">{titleTruncated}</span>
          <span className="eventSub">
            <>
              {!isVideo ? (
                <Moment date={startDateTime} format="LLLL" />
              ) : (
                <>{durationStr}</>
              )}
            </>
          </span>
        </div>
      </>
    ) : null;
  };

  const premierPillStr =
    tb_user_plan && (tb_user_plan === "signature" || tb_user_plan === "premier")
      ? tb_user_plan
      : "premier";
  //console.log(tb_user_plan);

  const renderEventTags = () => {
    return (
      <div className="eventTagWrapper">
        {eventTypeTranslated && (
          <div className="eyebrowLabel sub-tag" key={eventTypeTranslated}>
            {eventTypeTranslated}
          </div>
        )}
        {eventProductKey && (
          <div className={"eyebrowLabel " + eventProductKey}>
            {eventProductTranslated && eventProductTranslated}
          </div>
        )}
        {product &&
          productTagsTranslated &&
          product.slice(1, product.length).map(
            (productItem) =>
              productTagsTranslated[productItem] && (
                <div className="eyebrowLabel sub-tag" key={productItem}>
                  {productTagsTranslated[productItem]}
                </div>
              )
          )}
        {/*<span className="eyebrowLabel sub-tag">
          {eventTypeTranslated}
          </span>*/}
        {category &&
          categoryTagsTranslated &&
          category.map(
            (categoryItem) =>
              categoryTagsTranslated[categoryItem] && (
                <div className="eyebrowLabel sub-tag" key={categoryItem}>
                  {categoryTagsTranslated[categoryItem]}
                </div>
              )
          )}
        {/* {eventLanguage && languageTagTranslated && (
          <span className="eyebrowLabel sub-tag" key={eventLanguage}>
            {languageTagTranslated}
          </span>
        )} */}
      </div>
    );
  };

  const renderPremierTag = () => {
    return (
      <>
        {/* {eventTier && !expertCoachingFeature &&
          (eventTier === "premier" || eventTier === "signature") && (
            <span className={"eyebrowLabel tier-tag tier-tag_right"}>{premierPillStr}</span>
          )}
        {eventTier && expertCoachingFeature &&
          (eventTier === "premier" || eventTier === "signature") && (
            <div className={"eyebrowLabel tier-tag tier-tag_left"}>{premierPillStr}</div>
          )} */} 

          {eventTier &&
            (eventTier === "premier" || eventTier === "signature") && (
              <span className={"eyebrowLabel tier-tag tier-tag_left"}>{premierPillStr}</span>
          )}
      </>
    );
  };

  const renderECCMetadata = () => {
    return (
      <div className="eccmetadata">
        {metadataConcat}
      </div>
    );
  };

  let overviewCalendar = overview;

  let addressFormatted = formatTextContent(
    address && address.street ? address.street : ""
  );

  if (addressFormatted) {
    overviewCalendar = overviewCalendar + "\n\n" + addressFormatted;
  }

  let eventLaunchLink = launchLink;
  if (eventLaunchLink) {
    // replace /w/ with /s/
    eventLaunchLink = launchLink.replace(
      "salesforce.zoom.us/s/",
      "salesforce.zoom.us/w/"
    );

    overviewCalendar = `${overviewCalendar}\n\n${t(
      "Launch Event"
    )}:\n\n${eventLaunchLink}`;
  }

  const addEventLink = `https://${CLIENT_DOMAIN}/events/${id}`;

  overviewCalendar = `${overviewCalendar}\n\n${t(
    "Event Detail"
  )}:\n\n${addEventLink}`;

  const addLocation = addressFormatted
    ? addressFormatted
    : eventLaunchLink
    ? eventLaunchLink
    : region;

  const renderBtns = () => {
    let calendarEvent = {
      title,
      description: overviewCalendar,
      location: addLocation,
      startTime: startDateTime,
      endTime: endDateTime,
    };
    return (
      <div className="share-btn">
        <Button
          variant="secondary"
          className="complete-share-button"
          onClick={showShareModal}
        >
          <span className="complete-share-title">{t("Share This Event")}</span>
          <ShareIcon />
        </Button>
        <div className="calendar-btn">
          <AddToCalendar
            buttonLabel={t("Registration status Add to Calendar")}
            event={calendarEvent}
          />
        </div>
      </div>
    );
  };

  const renderCardBody = () => {
    return !minimized && !portraitFeature ? (
      <Card.Body
        style={showBtns ? { flexDirection: "column" } : {}}
        className={showBtns ? "withBtns" : {}}
      >
        <div className="cardBodyInner">
          <span className="eventTitle">{titleTruncated}</span>
          {primaryFeature && (
            <div className="eventOverview">{overviewTruncated}</div>
          )}
          {showDescription && (
            <div className="expertCoachingOverview">{overviewTruncated}</div>
          )}

        </div>
        {showBtns && renderBtns()}
        {showPremierTag && renderPremierTag()}
      </Card.Body>
    ) : null;
  };

  const renderCardHeader = () => {
    return (
      <>
      {!portraitFeature && !(cardStyle === "ecctrendingtopics") && (
        <Card.Header>
          {showLikes && (
            <Like
              id={id}
              tb_id={tb_id}
              t={t}
              isFavorite={isFavorite}
              event={event}
            />
          )}
          {renderCallout()}
          {renderHeaderSub()}
        </Card.Header>
      )}
      {!portraitFeature && (cardStyle === "ecctrendingtopics") && (
        <Card.Header>
          {showLikes && (
            <Like
              id={id}
              tb_id={tb_id}
              t={t}
              isFavorite={isFavorite}
              event={event}
            />
          )}
          {/* {renderCallout()}
          {renderHeaderSub()} */}
        </Card.Header>
      )}
      </>
      
    );
  };

  const renderCardFooter = () => {
    return (
      (cardStyle === "ecctrendingtopics") && (
        <div className="eventTagWrapperFooter">
          {eventTypeTranslated && (
            <div className="eccEventType" key={eventTypeTranslated}>
              {eventTypeTranslated}
            </div>
          )}
          {eventProductKey && (
            <div className={"eccProduct " + eventProductKey}>
              {eventProductTranslated && eventProductTranslated}
            </div>
          )}
        </div>
        
      )
    );
  };

  return (
    <EventLink {...eventLinkProps}>
      <Card className={cardClass} style={eventCardStyle}>
        {renderCardHeader()}
        {renderCardBody()}
        {renderPortraitFeature()}
        {renderCardFooter()}
      </Card>
    </EventLink>
  );
};

export default Event;
