/* eslint-disable react-hooks/exhaustive-deps */
import { UserActions } from "../../store/actions";
import "./GlobalNav.scss";

import React, { Suspense, lazy, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import {
  CLIENT_DOMAIN,
  COVEO_ORG_ID,
  COVEO_PLATFORM_API_KEY,
  IS_MOCK_TBID_USER,
} from "../../config/environment";

import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import i18next from "../../i18n";

import {
  initiateLogin,
  isAuthenticated,
  tbidNavLoginInitiated,
} from "@/utils/userHelper";
import { clear } from "../../utils/storage";

const { setUserTimezone, setUserLanguage, setUserInfo } = UserActions;

// lazy load settings to remove react-select, virtualized lists from initial load
const LanguageSelect = lazy(() =>
  import("../../components/LanguageSelect/LanguageSelect")
);
const LocationSelect = lazy(() =>
  import("../../components/LocationSelect/LocationSelect")
);

window.showLangTimeModal = () => {
  const event = new CustomEvent("showSettingsModal", {});
  window.dispatchEvent(event);
};

window.navlogin = () => {};

const tbidModule = `https://${CLIENT_DOMAIN}/assets/GlobalNavTBID.js`;
const coveo_org_id = COVEO_ORG_ID
  ? COVEO_ORG_ID
  : "coveo-org-id-not-configured";
const coveo_search_platform_api_key = COVEO_PLATFORM_API_KEY
  ? COVEO_PLATFORM_API_KEY
  : "coveo-search-api-key-not-configured";

const GlobalNav = ({ appConfig, language, tbidUser, location, dispatch }) => {
  const [contextNavData, setContextNavData] = useState();
  const [globalNavUrl, setGlobalNavUrl] = useState();
  const [isLoadingContext, setIsLoadingContext] = useState(true);

  const getContextNavJSON = async () => {
    setIsLoadingContext(true);

    const navData = i18next.t("GlobalNavLocal");
    // console.log("get navdata link: " + JSON.stringify(navData));
    const navJSON = await import(`./${navData}`);
    // console.log("getContext data: " + JSON.stringify(navJSON));
    setContextNavData(navJSON);

    setIsLoadingContext(false);
  };

  const getGlobalNavUrl = () => {
    const navUrl = i18next.t("GlobalNavURL");
    setGlobalNavUrl(navUrl);
  };

  useEffect(() => {
    window.addEventListener("showSettingsModal", handleSettingsModalShow);
    window.addEventListener("www_track", handleGlobalNavClick);
    window.addEventListener("www_spasearch", handleGlobalSearchClick);

    getGlobalNavUrl();
    getContextNavJSON();

    return () => {
      window.removeEventListener("showSettingsModal", handleSettingsModalShow);
      window.removeEventListener("www_track", handleGlobalNavClick);
      window.removeEventListener("www_spasearch", handleGlobalSearchClick);
    };
  }, [globalNavUrl]);

  const handleGlobalNavClick = (event) => {
    let type = event.detail.navItem;
    if (tbidUser && type === "login:logout") {
      dispatch(setUserInfo(null));
      clear();
      window.SFIDWidget && window.SFIDWidget.logout();
    } else if (tbidUser && type === "login:trailblazerLogout") {
      dispatch(setUserInfo(null));
      clear();
      window.SFIDWidget && window.SFIDWidget.logout();
    } else if (type === "login:trailblazer account") {
      tbidNavLoginInitiated();
    } else if (type === "login:login") {
      initiateLogin(IS_MOCK_TBID_USER);
    } else if (type === "login:loginHostLinks0") {
      initiateLogin(IS_MOCK_TBID_USER);
    }
  };

  const handleGlobalSearchClick = (event) => {
    const searchTerm = encodeURIComponent(event.detail.searchTerm);
    const path = `/search#q=${searchTerm}&tab=Events`;
    document.location.href = path;
  };

  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const handleSettingsModalClose = () => {
    setSettingsModalShow(false);
    window.location.replace(replaceSitemapLanguageParam(window.location.href));
    window.location.reload();
  };
  const handleSettingsModalShow = () => setSettingsModalShow(true);

  const { t } = useTranslation();
  const locationChange = (tz) => {
    let filterObject = { timezone: tz };
    dispatch(setUserTimezone(filterObject));
  };

  const replaceSitemapLanguageParam = (url) => {
    const urlObj = new URL(url);
    const fragment = urlObj.hash;
    // Check if the fragment contains 'f[sitemaplanguage]='
    if (fragment.includes("f[sitemaplanguage]=")) {
      // Replace the value after 'f[sitemaplanguage]=' with the new value
      const updatedFragment = fragment.replace(
        /f\[sitemaplanguage\]=[^&]+/,
        `f[sitemaplanguage]=${language}`
      );
      urlObj.hash = updatedFragment;
    }

    // Return the updated URL as a string
    return urlObj.toString();
  };

  const languageChange = (lng, locale) => {
    let filterObject = { language: lng, locale: locale };
    dispatch(setUserLanguage(filterObject));
    // getGlobalNavUrl();
    // getContextNavJSON();
  };

  function getCurrentNavLinks() {
    // return "[{&quot;ID&quot;:0,&quot;link&quot;:{&quot;label&quot;:&quot;My Events&quot;,&quot;ariaLabel&quot;:&quot;&quot;,&quot;url&quot;:&quot;/dashboard&quot;,&quot;target&quot;:&quot;_self&quot;}}
    // ,{&quot;ID&quot;:1,&quot;link&quot;:{&quot;label&quot;:&quot;Language / Timezone&quot;,&quot;ariaLabel&quot;:&quot;&quot;,&quot;url&quot;:&quot;javascript:window.showLangTimeModal();&quot;,&quot;target&quot;:&quot;_self&quot;}}]"
    let retVal;
    if (isAuthenticated()) {
      retVal = [
        {
          ID: 0,
          link: {
            label: "My Events",
            ariaLabel: "",
            url: "/dashboard",
            target: "_self",
          },
        },
        {
          ID: 1,
          link: {
            label: "Language / Timezone",
            ariaLabel: "",
            url: "javascript:window.showLangTimeModal()",
            target: "_self",
          },
        },
      ];
    } else {
      retVal = [
        {
          ID: 0,
          link: {
            label: t("Login"),
            ariaLabel: "",
            url: "javascript:window.navlogin()",
            target: "_self",
          },
        },
        {
          ID: 1,
          link: {
            label: t("Preferences"),
            ariaLabel: "",
            url: "javascript:window.showLangTimeModal()",
            target: "_self",
          },
        },
      ];
    }

    return JSON.stringify(retVal);
  }

  function getCurrentNavDescription() {
    let retVal;
    if (isAuthenticated()) {
      retVal = "";
    } else {
      retVal = "Success Event Description";
    }
    return retVal;
  }

  const eventsNavLinks = getCurrentNavLinks();

  const eventsNavLinksDescription = getCurrentNavDescription();

  if (isLoadingContext)
    return (
      <>
        {/* settings modal */}
        <Modal
          show={settingsModalShow}
          onHide={handleSettingsModalClose}
          className="settingsModal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Settings")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item className="labelSet">
                <span className="modalSubhead">{t("Set Language")}</span>
                <Suspense fallback={<div></div>}>
                  <LanguageSelect
                    options={appConfig.filters.eventLanguage.options}
                    selected={language}
                    changeCallback={languageChange}
                  />
                </Suspense>
              </ListGroup.Item>
              <ListGroup.Item className="labelSet">
                <span className="modalSubhead">{t("Set Location")}</span>
                <Suspense fallback={<div></div>}>
                  <LocationSelect changeCallback={locationChange} />
                </Suspense>
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="mr-auto"
              onClick={handleSettingsModalClose}
            >
              {t("Update")}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <div className="globalnav--wrapper-c360">
        </div> */}
      </>
    );
  if (!contextNavData)
    return (
      <>
        {/* settings modal */}
        <Modal
          show={settingsModalShow}
          onHide={handleSettingsModalClose}
          className="settingsModal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Settings")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item className="labelSet">
                <span className="modalSubhead">{t("Set Language")}</span>
                <Suspense fallback={<div></div>}>
                  <LanguageSelect
                    options={appConfig.filters.eventLanguage.options}
                    selected={language}
                    changeCallback={languageChange}
                  />
                </Suspense>
              </ListGroup.Item>
              <ListGroup.Item className="labelSet">
                <span className="modalSubhead">{t("Set Location")}</span>
                <Suspense fallback={<div></div>}>
                  <LocationSelect changeCallback={locationChange} />
                </Suspense>
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="mr-auto"
              onClick={handleSettingsModalClose}
            >
              {t("Update")}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <div className="globalnav--wrapper-c360">
        </div> */}
      </>
    );
  return (
    <>
      {/* settings modal */}
      <Modal
        show={settingsModalShow}
        onHide={handleSettingsModalClose}
        className="settingsModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Settings")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item className="labelSet">
              <span className="modalSubhead">{t("Set Language")}</span>
              <Suspense fallback={<div></div>}>
                <LanguageSelect
                  options={appConfig.filters.eventLanguage.options}
                  selected={language}
                  changeCallback={languageChange}
                />
              </Suspense>
            </ListGroup.Item>
            <ListGroup.Item className="labelSet">
              <span className="modalSubhead">{t("Set Location")}</span>
              <Suspense fallback={<div></div>}>
                <LocationSelect changeCallback={locationChange} />
              </Suspense>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="mr-auto"
            onClick={handleSettingsModalClose}
          >
            {t("Update")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="globalnav--wrapper-c360" key={globalNavUrl}>
        <hgf-c360nav
          search="true"
          search-spa="true"
          search-organization-id={coveo_org_id}
          search-access-token={coveo_search_platform_api_key}
          search-placeholder="Search Success Events"
          // search-site="th"
          // search-site-url="https://trailhead.salesforce.com/search/"
          //locale="en"
          show-region-selector="false"
          show-button-main="false"
          contact-menu-override="true"
          origin={globalNavUrl} //{t(`GlobalNavURL`)} //"https://wp.salesforce.com/en-us/wp-json"
          tbid-module-override={tbidModule}
          // context-nav-enabled="true"
          login-host-title={t("Success Events")}
          login-host-description={t(`${eventsNavLinksDescription}`)}
          login-host-links={eventsNavLinks}
        ></hgf-c360nav>

        <hgf-c360contextnav
          data={JSON.stringify(contextNavData)}
        ></hgf-c360contextnav>
      </div>
    </>
  );
};

export default withRouter(GlobalNav);
