/* eslint-disable react-hooks/exhaustive-deps */
import { EventActions, UserActions } from "../../store/actions";
import React, { useEffect, useState } from "react";
import { TBID_DOMAIN } from "../../config/environment";
import { useTranslation } from "react-i18next";

import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Jumbotron from "react-bootstrap/Jumbotron";
import UpcomingEventsContainer from "../../containers/UpcomingEventsContainer";
import PastEventsContainer from "../../containers/PastEventsContainer";
import FavoritedEventsContainer from "../../containers/FavoritedEventsContainer";
import { HeroBanner } from "../../components/HeroBanner";
import { PromoSection } from "../../components/PromoSection";
import { ErrorPage } from "../ErrorPage";

import { ReactComponent as ExternalUrlIcon } from "../../assets/images/icon_external_url.svg";

import "./Dashboard.scss";

const { updateEventFiltersSilent } = EventActions;

const { getUserRegistrations, getUserFavorites, getUserFavoritesAll } =
  UserActions;

const Dashboard = ({ criticalError, dispatch, user, tb_id }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (tb_id) {
      dispatch(getUserFavoritesAll({ tb_id: user.tb_id }));
      dispatch(getUserFavorites({ tb_id: user.tb_id, currentCounter: 0 }));
      dispatch(
        getUserRegistrations({
          tbidId: user.tb_id,
          status: "upcoming",
          currentCounter: 0,
        })
      );
      dispatch(
        getUserRegistrations({
          tbidId: user.tb_id,
          status: "previous",
          currentCounter: 0,
        })
      );
      if (!hasFavoritesInit) {
        dispatch(getUserFavoritesAll({ tb_id: user.tb_id }));
        setHasFavoritesInit(true);
      }
    }

    // clear search terms on homepage
    const filterObjectSearch = { key: "search", values: null };
    dispatch(updateEventFiltersSilent(filterObjectSearch));
  });

  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);

  const pageTitle = t("Dashboard") + " - " + t("Customer Events");
  const userProfileLink = `https://${TBID_DOMAIN}/id`;

  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  if (!tb_id) {
    return <Redirect to="/" />;
  }

  let userPremierStatus = "";
  let userIsPremier = false;
  let userPremierPromoKey = "premier";
  let userIsStandard = false;

  if (
    user &&
    (user.success_plan === "signature" || user.success_plan === "premier")
  ) {
    userPremierStatus =
      user.success_plan === "signature" ? "Signature" : "Premier";
    userIsPremier = true;
    userPremierPromoKey = user.success_plan;
  } else if (user && user.success_plan === "standard") {
    userIsStandard = true;
  }

  const RenderHeroBanner = () => {
    return (
      <HeroBanner
        uniqueImageStyle={{ transform: "translate(10px, 10px)" }}
        minHeight="379px"
        eventProductKey="default"
        eventTypeKey="hero-panel"
        user={user}
      >
        <Row>
          <Col xs={10} lg={6}>
            <Jumbotron>
              <h1>
                {user && user.first_name} {user && user.last_name}
              </h1>
              <h4>
                {user && user.company}
                {userPremierStatus && (
                  <>
                    {" | "}
                    <span className="userPremierStatus">
                      {t("Premier User Dashboard title", {
                        premier_status: userPremierStatus,
                      })}
                    </span>
                  </>
                )}
              </h4>
              {user && (
                <a
                  href={userProfileLink}
                  className={"edit-profile_link"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("User dashboard Edit Profile")}
                </a>
              )}
            </Jumbotron>
          </Col>
        </Row>
      </HeroBanner>
    );
  };

  const RenderUpcomingEvents = () => {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <UpcomingEventsContainer />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const RenderPastEvents = () => {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <PastEventsContainer />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const RenderFavoritedEvents = () => {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <FavoritedEventsContainer />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const RenderPromoExplore = () => {
    return (
      <PromoSection promoTypeKey="explore">
        <h3 className="groupHeader">{t("Explore promo Title")}</h3>
        <p>{t("Explore promo Description")}</p>
        <Link
          to={{
            pathname: "/search",
            hash: "#tab=Events",
          }}
          className="btn btn-primary"
        >
          {t("Explore All Events")}
        </Link>

        <h4 className="groupSubHeader">{t("Explore promo CTA")}</h4>
        <div className="groupLinkSet">
          {" "}
          {!userIsStandard ? (
            <Link
              to={{
                pathname: "/search",
                hash: "#tab=Events&f[successeventeventtype]=ask-an-expert",
              }}
              className="d-inline-block"
            >
              {t("Ask an Expert")}
            </Link>
          ) : (
            <Link
              to={{
                pathname: "/search",
                hash: "#tab=Events&f[successeventeventtype]=in-person-event",
              }}
              className="d-inline-block"
            >
              {t("In Person Event")}
            </Link>
          )}
          <Link
            to={{
              pathname: "/search",
              hash: "#tab=Events&f[successeventeventtype]=virtual-interactive-workshop",
            }}
            className="d-inline-block"
          >
            {t("Virtual Interactive Workshop")}
          </Link>
          <br />
          <Link
            to={{
              pathname: "/search",
              hash: "#tab=Events&f[successeventeventtype]=webinar",
            }}
            className="d-inline-block"
          >
            {t("Webinar")}
          </Link>
          {!userIsStandard ? (
            <Link
              to={{
                pathname: "/successplanevents/expertcoaching",
                hash: "#tab=Coaching",
              }}
              className="d-inline-block"
            >
              {t("Expert Coaching Session")}
            </Link>
          ) : (
            <Link
              to={{
                pathname: "/search",
                hash: "#tab=Events&f[successeventeventtype]=video",
              }}
              className="d-inline-block"
            >
              {t("Video")}
            </Link>
          )}
        </div>
      </PromoSection>
    );
  };

  const RenderPromoPremier = () => {
    return (
      <PromoSection
        promoTypeKey={`${userPremierPromoKey}-dashboard`}
        imageAlignment="last"
      >
        <h3 className="groupHeader">
          {t("Premier promo Dashboard Title", {
            premier_status: userPremierStatus,
          })}
        </h3>
        <p>
          {t("Premier promo Dashboard Description", {
            premier_status: userPremierStatus,
          })}
        </p>
        <a
          className="btn btn-primary icon-button"
          href={`https://cloud.mail.salesforce.com/success-center/success-plans/?utm_source=success_events&utm_medium=${userPremierPromoKey}_dash_banner`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Premier promo Dashboard CTA", {
            premier_status: userPremierStatus,
          })}
          <ExternalUrlIcon />
        </a>
      </PromoSection>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <RenderHeroBanner />
      <RenderUpcomingEvents />
      {userIsPremier && <RenderPromoPremier />}
      <Container className={"divider"}></Container>
      <RenderFavoritedEvents />
      <RenderPastEvents />
      <RenderPromoExplore />
    </div>
  );
};

export default Dashboard;
