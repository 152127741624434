import { headlessEngine, trendingEngine } from "@/configureCoveo";
import {
  buildBreadcrumbManager,
  buildDateFacet,
  buildDateRange,
  buildFacet,
  buildPager,
  buildQueryExpression,
  buildQuerySummary,
  buildResultList,
  buildResultTemplatesManager,
  buildSearchBox,
  buildSearchParameterManager,
  buildTab,
} from "@coveo/headless";
import { buildRecommendationList } from "@coveo/headless/recommendation";
import moment from "moment-timezone";

export const searchBox = buildSearchBox(headlessEngine, {
  options: {
    highlightOptions: {
      notMatchDelimiters: {
        open: "<strong>",
        close: "</strong>",
      },
      correctionDelimiters: {
        open: "<i>",
        close: "</i>",
      },
    },
  },
});

export const querySummary = buildQuerySummary(headlessEngine);

export const resultList = buildResultList(headlessEngine, {
  options: {
    fieldsToInclude: [
      "successeventitemtype",
      "successeventeventtype",
      "successeventportraittype",
      "successeventproduct",
      "successeventregion",
      "successeventeventtype",
      "successeventstartdate",
      "successeventenddate",
      "successeventitemtier",
      "successeventdescription",
      "successeventvideoduration",
      "successeventdeliverytypesubtype",
      "successeventdeliverytype",
      "successeventitemcategory",
      "successeventitemsubcategory",
      "successeventtargetcustomerrole",
      "successeventlevelofexpertise",
    ],
  },
});

export const pager = buildPager(headlessEngine);

export const productFacet = buildFacet(headlessEngine, {
  options: {
    field: "successeventproduct",
    numberOfValues: 100,
  },
});

export const categoryFacet = buildFacet(headlessEngine, {
  options: { field: "successeventitemcategory", numberOfValues: 100 },
});

export const subCategoryFacet = buildFacet(headlessEngine, {
  options: { field: "successeventitemsubcategory", numberOfValues: 100 },
});

export const customerRoleFacet = buildFacet(headlessEngine, {
  options: { field: "successeventtargetcustomerrole", numberOfValues: 100 },
});

export const eventTypeFacet = buildFacet(headlessEngine, {
  options: { field: "successeventeventtype", numberOfValues: 100 },
});

export const deliveryTypeSubTypeFacet = buildFacet(headlessEngine, {
  options: { field: "successeventdeliverytypesubtype", numberOfValues: 100 },
});

export const tierFacet = buildFacet(headlessEngine, {
  options: { field: "successeventitemtier", numberOfValues: 100 },
});

export const regionFacet = buildFacet(headlessEngine, {
  options: { field: "successeventregion", numberOfValues: 100 },
});

export const languageFacet = buildFacet(headlessEngine, {
  options: { field: "sitemaplanguage", numberOfValues: 100 },
});

export const businesslineFacet = buildFacet(headlessEngine, {
  options: {
    field: "successeventbusinessline",
    numberOfValues: 100,
    sortCriteria: "alphanumeric",
  },
});

export const customerlifecycleFacet = buildFacet(headlessEngine, {
  options: { field: "successeventcustomerlifecycle", numberOfValues: 100 },
});

export const deliveryRoleExpertiseFacet = buildFacet(headlessEngine, {
  options: { field: "successeventlevelofexpertise", numberOfValues: 100 },
});

const currentHour = moment().hour();
const currentDay = moment().day();
export const dateFacet = buildDateFacet(headlessEngine, {
  options: {
    field: "successeventenddate",
    generateAutomaticRanges: false,
    currentValues: [
      buildDateRange({
        start: { period: "now" },
        end: { period: "next", unit: "hour", amount: 24 - currentHour },
      }),
      buildDateRange({
        start: { period: "now" },
        end: { period: "next", unit: "day", amount: 7 - currentDay },
      }),
      buildDateRange({
        start: { period: "next", unit: "day", amount: 7 - currentDay },
        end: { period: "next", unit: "day", amount: 14 - currentDay },
      }),
    ],
  },
});

export const breadcrumbs = buildBreadcrumbManager(headlessEngine);

export const searchParametersFacet = buildSearchParameterManager(
  headlessEngine,
  {
    initialState: {
      parameters: {},
    },
  }
);

export const searchParametersFacetDateRange = buildSearchParameterManager(
  headlessEngine,
  {
    initialState: {
      parameters: {},
    },
  }
);

export const searchParametersBreadcrumbs = buildSearchParameterManager(
  headlessEngine,
  {
    initialState: {
      parameters: {},
    },
  }
);

export const allTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "All",
    expression: "",
  },
});

export const eventsTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "Events",
    expression: "@successeventitemtype==event",
  },
});

export const portraitsTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "Topics",
    expression: "@successeventportraittype==topic",
  },
});

export const coachingTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "Coaching",
    expression: "@successeventportraittype==coaching",
  },
});

const eccOverviewQuery = buildQueryExpression()
  .addStringField({
    field: "successeventitemtype",
    operator: "isExactly",
    values: ["event"],
  })
  .toQuerySyntax();

export const eccOverviewTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "Overview",
    expression: eccOverviewQuery,
  },
});

const eccCoachingQuery = buildQueryExpression()
  .addStringField({
    field: "successeventitemtype",
    operator: "isExactly",
    values: ["portrait"],
  })
  .addStringField({
    field: "successeventportraittype",
    operator: "isExactly",
    values: ["coaching"],
  })
  .toQuerySyntax();

export const eccCoachingTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "Coaching",
    expression: eccCoachingQuery,
  },
});

const eccAdminfundamentalsQuery = buildQueryExpression()
  .addStringField({
    field: "successeventitemtype",
    operator: "isExactly",
    values: ["event"],
  })
  .addStringField({
    field: "successeventeventtype",
    operator: "isExactly",
    values: ["webinar"],
  })
  .addStringField({
    field: "title",
    operator: "contains",
    values: ["admin fundamentals", "簡単設定支援プログラム"],
  })
  .toQuerySyntax();

export const eccAdminfundamentalsTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "Fundamentals",
    expression: eccAdminfundamentalsQuery,
  },
});

const eccOfficeHoursQuery = buildQueryExpression()
  .addStringField({
    field: "successeventitemtype",
    operator: "isExactly",
    values: ["event"],
  })
  .addStringField({
    field: "successeventeventtype",
    operator: "isExactly",
    values: ["ask-an-expert"],
  })
  .toQuerySyntax();

export const eccOfficeHoursTab = buildTab(headlessEngine, {
  initialState: { isActive: false },
  options: {
    id: "Office",
    expression: eccOfficeHoursQuery,
  },
});

export const headlessResultTemplateManager =
  buildResultTemplatesManager(headlessEngine);

export const recommendationController = buildRecommendationList(trendingEngine);
