/* eslint-disable react-hooks/exhaustive-deps */
import { EventActions, UserActions } from "../../store/actions";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useFilterLinkConfig } from "../../hooks/";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Jumbotron from "react-bootstrap/Jumbotron";

import EventFilterGroupContainer from "../../containers/EventFilterGroupContainer";
import FeaturedEventsContainer from "../../containers/FeaturedEventsContainer";

import { HeroBanner } from "../../components/HeroBanner";
import { PromoSection } from "../../components/PromoSection";

import { ErrorPage } from "../ErrorPage";

import { ReactComponent as ExternalUrlIcon } from "../../assets/images/icon_external_url.svg";

import { IS_MOCK_TBID_USER } from "@/config/environment";
import { initiateLogin } from "@/utils/userHelper";

import "./Home.scss";

const { loadEventFilterGroup, loadFeaturedEvents, updateEventFiltersSilent } =
  EventActions;

const { getUserFavoritesAll } = UserActions;

const Home = ({
  filterGroups,
  criticalError,
  dispatch,
  user,
  tb_id,
  appConfig,
}) => {
  const { t } = useTranslation();
  const filterConfigParams = useFilterLinkConfig();

  useEffect(() => {
    loadFilterGroups();
    initFeaturedEvents();

    if (tb_id && !hasFavoritesInit) {
      dispatch(getUserFavoritesAll({ tb_id }));
      setHasFavoritesInit(true);
    }

    // clear search terms on homepage
    const filterObjectSearch = { key: "search", values: null };
    dispatch(updateEventFiltersSilent(filterObjectSearch));
  });

  const [hasFavoritesInit, setHasFavoritesInit] = useState(false);

  const loadFilterGroups = () => {
    filterGroups.map((group, index) => dispatch(loadEventFilterGroup(group)));
  };

  const initFeaturedEvents = () => {
    dispatch(loadFeaturedEvents());
  };
  // when login is clicked
  const trailblazerLogin = async () => {
    initiateLogin(IS_MOCK_TBID_USER, "/");
  };

  const pageTitle = t("Home") + " - " + t("Customer Success Events");

  if (criticalError) {
    return <ErrorPage errorType="500" />;
  }

  let eventTypeKey = "dashboard";
  let premierStatus = "Premier";
  let userPremierPromoKey = "premier";
  let userIsStandard = false;

  if (user && user.success_plan) {
    if (user.success_plan === "signature" || user.success_plan === "premier") {
      eventTypeKey = "dashboard-premier";
      premierStatus =
        user.success_plan === "signature" ? "Signature" : "Premier";
      userPremierPromoKey = user.success_plan;
    } else if (user.success_plan === "standard") {
      userIsStandard = true;
    }
  }

  const RenderHeroBanner = () => {
    return (
      <HeroBanner
        minHeight="379px"
        eventProductKey="default"
        eventTypeKey={eventTypeKey}
        user={user}
      >
        <Row>
          <Col xs={7} lg={6}>
            <Jumbotron>
              <h1>{t("Customer Success Events")}</h1>

              <Link
                to={{
                  pathname: "/search",
                  hash: "#tab=events",
                }}
                className="btn btn-primary"
              >
                {t("Explore All Events")}
              </Link>
            </Jumbotron>
          </Col>
        </Row>
      </HeroBanner>
    );
  };

  const RenderPremierFooterBanner = () => {
    return (
      <HeroBanner
        minHeight="379px"
        eventProductKey="default"
        eventTypeKey="footer-banner-premier"
        user={user}
        layoutType="footer"
      >
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
          >
            <Jumbotron>
              <h3 className="groupHeader">
                {t("Premier footer promo Title", {
                  premier_status: premierStatus,
                })}
              </h3>
              <p>
                {t("Premier footer promo Description", {
                  premier_status: premierStatus,
                })}
              </p>
              <a
                className="btn btn-primary icon-button"
                href="https://cloud.mail.salesforce.com/success-center/success-plans/?utm_source=success_events&utm_medium=standard_home_footer"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Premier footer promo Learn More CTA", {
                  premier_status: premierStatus,
                })}
                <ExternalUrlIcon />
              </a>
            </Jumbotron>
          </Col>
        </Row>
      </HeroBanner>
    );
  };

  const RenderFeaturedEvents = () => {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <FeaturedEventsContainer />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const RenderPromoPremier = () => {
    return (
      <PromoSection promoTypeKey="premier" imageAlignment="last">
        <h3 className="groupHeader">
          {t("Premier promo Title", {
            premier_status: premierStatus,
          })}
        </h3>
        <p>
          {t("Premier promo Description", {
            premier_status: premierStatus,
          })}
        </p>
        {/* TBID login button */}
        <button className="btn btn-primary" onClick={trailblazerLogin}>
          {t("Premier promo Login CTA", {
            premier_status: premierStatus,
          })}
        </button>
        <a
          className="btn icon-button fill-mode side-btn btn-secondary"
          href="https://cloud.mail.salesforce.com/success-center/success-plans/?utm_source=success_events&utm_medium=unkwn_home_banner"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Premier promo Learn More CTA", {
            premier_status: premierStatus,
          })}
          <ExternalUrlIcon />
        </a>
      </PromoSection>
    );
  };

  const RenderPromoPremierAuthenticated = () => {
    return (
      <PromoSection promoTypeKey="premier" imageAlignment="last">
        <h3 className="groupHeader">
          {t("Premier promo Dashboard Title", {
            premier_status: premierStatus,
          })}
        </h3>
        <p>
          {t("Premier promo Dashboard Description", {
            premier_status: premierStatus,
          })}
        </p>
        <a
          className="btn btn-primary icon-button"
          href={`https://cloud.mail.salesforce.com/success-center/success-plans/?utm_source=success_events&utm_medium=${userPremierPromoKey}_home_banner`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Premier promo Dashboard CTA", {
            premier_status: premierStatus,
          })}
          <ExternalUrlIcon />
        </a>
      </PromoSection>
    );
  };

  const RenderPromoExplore = () => {
    return (
      <PromoSection promoTypeKey="explore" imageAlignment="last">
        <h3 className="groupHeader">{t("Explore promo Title")}</h3>
        <p>{t("Explore promo Description")}</p>
        <Link
          to={{
            pathname: "/search",
            hash: "#tab=Events",
          }}
          className="btn btn-primary"
        >
          {t("Explore All Events")}
        </Link>

        <h4 className="groupSubHeader">{t("Explore promo CTA")}</h4>
        <div className="groupLinkSet">
          {" "}
          {!userIsStandard ? (
            <Link
              to={{
                pathname: "/search",
                hash: "#tab=Events&f[successeventeventtype]=ask-an-expert",
              }}
              className="d-inline-block"
            >
              {t("Ask an Expert")}
            </Link>
          ) : (
            <Link
              to={{
                pathname: "/search",
                hash: "#tab=Events&f[successeventeventtype]=in-person-event",
              }}
              className="d-inline-block"
            >
              {t("In Person Event")}
            </Link>
          )}
          <Link
            to={{
              pathname: "/search",
              hash: "#tab=Events&f[successeventeventtype]=virtual-interactive-workshop",
            }}
            className="d-inline-block"
          >
            {t("Virtual Interactive Workshop")}
          </Link>
          <br />
          <Link
            to={{
              pathname: "/search",
              hash: "#tab=Events&f[successeventeventtype]=webinar",
            }}
            className="d-inline-block"
          >
            {t("Webinar")}
          </Link>
          {!userIsStandard ? (
            <Link
              to={{
                pathname: "/successplanevents/expertcoaching",
                hash: "#tab=Coaching",
              }}
              className="d-inline-block"
            >
              {t("Expert Coaching Session")}
            </Link>
          ) : (
            <Link
              to={{
                pathname: "/search",
                hash: "#tab=Events&f[successeventeventtype]=video",
              }}
              className="d-inline-block"
            >
              {t("Video")}
            </Link>
          )}
        </div>
      </PromoSection>
    );
  };

  const RenderPromoExpertCoaching = () => {
    const featuredProducts = [
      "sales",
      "marketing",
      "platform",
      "service",
      "commerce",
      "analytics",
    ];

    const featuredProductsFilters = [
      "sales",
      "marketing,marketing-cloud-account-engagement,marketing-cloud-intelligence,marketing-cloud-personalization",
      "platform",
      "service",
      "commerce",
      "crm-analytics,tableau",
    ];

    return (
      <PromoSection promoTypeKey="expert-coaching">
        <h3 className="groupHeader">{t("Expert Coaching promo Title")}</h3>
        <p>{t("Expert Coaching promo Description")}</p>

        <h4 className="groupSubHeader">{t("Expert Coaching promo CTA")}</h4>
        <Row className="groupLinkSet">
          {featuredProducts.map((featuredProduct,index) => (
            <Col xs={12} md={6} xl={4}>
              <Link
                to={{
                  pathname: "/search",
                  hash: `#tab=Coaching&f[successeventproduct]=${featuredProductsFilters[index]}`,
                }}
                className={`d-inline-block product-link ${featuredProduct}`}
              >
                {t(`Product Line Title ${featuredProduct}`)}
              </Link>
            </Col>
          ))}
        </Row>
      </PromoSection>
    );
  };

  const RenderFilterGroup = ({ start, end, mode = "default" }) => {
    return (
      <Container>
        <Row>
          <Col xs={12} lg={12}>
            {filterGroups.slice(start, end).map((group, index) => (
              <EventFilterGroupContainer
                groupKey={group.groupkey}
                groupConfig={group}
                key={group.groupkey}
                mode={mode}
              />
            ))}
          </Col>
        </Row>
      </Container>
    );
  };

  const renderStandard =
    user && user.success_plan && user.success_plan === "standard";

  const metaDescription = t("Default Meta Description");

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
      </Helmet>
      <RenderHeroBanner />
      <RenderFeaturedEvents />
      {!renderStandard && (
        <>
          {tb_id ? <RenderPromoPremierAuthenticated /> : <RenderPromoPremier />}
          <div className="bandedSection--white">
            <RenderFilterGroup start={0} end={1} />
            <RenderPromoExpertCoaching />
            <RenderFilterGroup start={1} end={2} mode="noborder" />
            <RenderPromoExplore />
            <RenderFilterGroup start={2} end={3} />
          </div>
        </>
      )}
      {renderStandard && (
        <>
          <div className="bandedSection--white">
            <RenderPromoExpertCoaching />
            <RenderFilterGroup start={0} end={2} />
            <RenderPromoExplore />
            <RenderFilterGroup start={2} end={3} />
          </div>
          <RenderPremierFooterBanner />
        </>
      )}
    </div>
  );
};

export default Home;
