import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import "./ProductTile.scss";

const ProductTile = ({ title, icon, href, subtitle }) => {
  const { t } = useTranslation();
  const [imageSource, setImageSource] = useState(undefined);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    async function getImages() {
      const img = await import(
        `../../assets/images/producttile-images/${icon}.png`
      )
        .then((image) => image.default)
        .catch((error) => {
          console.debug("error", error);
        });

      const img2x = await import(
        `../../assets/images/producttile-images/${icon}@2x.png`
      )
        .then((image) => image.default)
        .catch((error) => {
          console.debug("error", error);
        });

      // If the image doesnt exist, fail gracefully
      if (!img || !img2x) return;
      setImageSource({
        default: img,
        retina: img2x,
      });
    }

    if (icon) {
      getImages();
    }
  }, [icon]);

  function imageOnLoad() {
    setImageLoaded(true);
  }

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div className="productTileWrapper">
        <div className="productTileImage">
          {imageSource && (
            <img
              className={`image ${imageLoaded && "image-loaded"}`}
              src={imageSource.default}
              srcSet={`${imageSource.retina} 2x`}
              onLoad={imageOnLoad}
              alt={icon}
            />
          )}
        </div>
        <div className="productTileContent">
          <div>{`${title}`}</div>
          <div className="productSubtitle">{`${t(subtitle)}`}</div>
        </div>
      </div>
    </a>
  );
};

ProductTile.defaultProps = {
  title: "",
  icon: "",
  subtitle: "",
};

ProductTile.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ProductTile;
